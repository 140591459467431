<template>
  <div id="main">
    <el-divider content-position="left">如果您无法与我们取得联系，可通过下方式的在线留言给我们发短信，我们会第一时间与您联系，谢谢！</el-divider>
    <div class="content">
      <div class="content-left">
        <el-form ref="form" :model="form" :rules="rulesForm" label-width="100px">
          <el-form-item label="姓名：" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="您是：" label-width="100px">
            <el-radio-group v-model="form.gender">
              <el-radio :label="true">男士</el-radio>
              <el-radio :label="false">女士</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="手机：" prop="phone">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="固话：">
            <el-input v-model="form.fixedTelephone"></el-input>
          </el-form-item>
          <el-form-item label="邮箱：" prop="email">
            <el-input v-model="form.email"></el-input>
          </el-form-item>
          <el-form-item label="QQ/微信：">
            <el-input v-model="form.qqOrWeiChat"></el-input>
          </el-form-item>
          <el-form-item label="希望我们如何联系您：" label-width="160px">
            <el-radio-group v-model="form.contactType">
              <el-radio label="手机"></el-radio>
              <el-radio label="固话"></el-radio>
              <el-radio label="邮箱"></el-radio>
              <el-radio label="QQ/微信"></el-radio>
              <el-radio label="短信"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="详细内容：" prop="content">
            <el-input type="textarea" v-model="form.content" placeholder="在此处填写详细内容，以便更快了解您的情况！"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit('form')">提交</el-button>
            <el-button @click="restForm('form')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="content-right">
        <img src="../../images/二维码.jpg" alt="">
        <span class="info">关注微信公众号直接咨询</span>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "OnlineMessage",
  data() {
    var checkPhone = (rule, value, callback)=>{

      let reg = /^1[345789][0-9]{9}$/g

      if(reg.test(value)){
        return callback()
      }
      return new callback(new Error("请输入正确的手机号"))
    }
    return {
      form: {
        name: '',
        gender: true,
        phone: '',
        fixedTelephone: '',
        email: '',
        qqOrWeiChat: '',
        contactType: '手机',
        content: ''
      },
      rulesForm: {
        name: [
          {required: true, message: '名字不能为空', trigger: 'blur'},
        ],
        phone: [
          {required: true, message: '手机不能为空', trigger: 'blur'},
          {validator: checkPhone, trigger: ['blur', 'change']}
        ],
        email: [
          {type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change']},
        ],
        content: [
          {required: true, message: '留言内容不能为空', trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    /**
     *
     *  提交form表单事件
     **/
    onSubmit(refName) {
      this.$refs[refName].validate((valid)=>{
        if (valid){
          this.$http.post("/commenter/insert", this.form).then(({data}) => {
            if (data.code===200){
              this.restForm(refName)
              return this.$message.success("留言成功")
            }
            return this.$message.error("留言失败，请重试")
          })
        }
        return false
      })
    },
    /**
     *
     *  重置表单按键事件
     **/
    restForm(refName){
      this.$refs[refName].resetFields()
    }
  }
}
</script>

<style scoped>
#main {
  width: 1200px;
  margin: 155px auto 0 auto;
}

.content {
  width: 100%;
}

.content-left {
  display: inline-block;
  width: 60%;
}

.content-right {
  display: inline-block;
  width: 40%;
  height: 622px;
  vertical-align: top;
  text-align: center;
}

.content-right img {
  display: inline-block;
  width: 60%;
  height: 50%;
  margin-top: 20%;


}

.content-right .info {
  display: block;
  margin-top: 10px;
  font-size: 16px;
}

.el-form {
  margin-top: 50px !important;
}

.el-divider__text {
  font-size: 20px !important;
  font-weight: 700;
}
</style>