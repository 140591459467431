<template>
    <div id="main">
      <img id="bg" src="../../images/contactUsBg.jpg" alt="" class="clearfix">
      <h1>联系我们</h1>
      <ul class="list">
        <li>
          <img src="../../images/手机.png" alt="">
          <div class="contactName" style="margin-top: 10px;color: #909399;font-weight: bold;font-size: 14px">Mobile</div>
          <div class="contactValue" style="margin-top: 10px;color: #606266;font-weight: bold;font-size: 16px">18007485818</div>
        </li>
        <li>
          <img src="../../images/邮箱.png" alt="">
          <div class="contactName" style="margin-top: 10px;color: #909399;font-weight: bold;font-size: 14px">Email</div>
          <div class="contactValue" style="margin-top: 10px;color: #606266;font-weight: bold;font-size: 16px">380221803@qq.com</div>
        </li>
        <li>
          <img src="../../images/地址.png" alt="">
          <div class="contactName" style="margin-top: 10px;color: #909399;font-weight: bold;font-size: 14px">Address</div>
          <div class="contactValue" style="margin-top: 10px;color: #606266;font-weight: bold;font-size: 16px">湖南省长沙市高新区中电软件园总部大楼1012</div>
        </li>
      </ul>
      <div class="contact-Map">
        <el-divider content-position="left" ><span style="font-size: 16px; font-weight: bold">我们的位置</span></el-divider>
        <img src="../../images/MapUs.png" alt="">
      </div>

    </div>
</template>

<script>
export default {
  name: "ContactUs"
}
</script>

<style scoped>
#main{
  width: 1200px;
  margin: 110px auto 0 auto;
}
 #bg{
  position: absolute;
  top: 110px;
  left: 0;
  width: 100%;
  height: 400px;
}
h1{
  margin-top: 425px;
  font-size: 30px;
  text-align: center;
}
.list{
 text-align: center;
}
.list li{
  display: inline-block;
  width: 200px;
  height: 200px;
  margin: 20px 100px;
  vertical-align: top;
}
.list li img{
  text-align: center;
  width: 70px;
  height: 70px;
}
.contact-Map{
  margin-bottom: 20px;
}
.contact-Map img{
  width: 100%;
}

</style>